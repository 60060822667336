import React, { useEffect, useContext, useState, useRef } from "react";
import Swal from "sweetalert2";

import AuthService from "../../../services/User/AuthService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import OtpDialog from "./OtpDialog";

const Signup = () => {
  const loadingCtx = useContext(LoadingContext);

  const [shopType, setShopType] = useState();

  const nameRef = useRef();
  const shopNameRef = useRef();
  const mobileRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  const signupHandler = async () => {
    const name = nameRef.current.value;
    const shopName = shopNameRef.current.value;
    const mobile = mobileRef.current.value;
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (!latitude || !longitude) {
      Swal.fire(
        "Opps!",
        "Unable to retrieve your location, please enable location access in your browser",
        "error"
      );
      return;
    }

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    if (!shopName) {
      Swal.fire("Opps!", "Invalid Shop Name", "error");
      return;
    }

    if (!shopType) {
      Swal.fire("Opps!", "Invalid Shop Type", "error");
      return;
    }

    if (!mobile) {
      Swal.fire("Opps!", "Invalid Mobile Number", "error");
      return;
    }

    if (!email) {
      Swal.fire("Opps!", "Invalid Email ID", "error");
      return;
    }

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (!email.match(isValidEmail)) {
      Swal.fire("Opps!", "Invalid Email ID", "error");
      return;
    }

    if (!password) {
      Swal.fire("Opps!", "Invalid Password", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const data = await AuthService.signup(
        name,
        shopName,
        shopType,
        mobile,
        email,
        password,
        latitude,
        longitude
      );

      if (!data) {
        throw new Error("Request Failed!");
      }

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        const token = data.data.token;

        setOpen(true);
        setErrMsg(message);
        setToken(token);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const [open, setOpen] = useState(false);
  const [token, setToken] = useState();
  const [errMsg, setErrMsg] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const getLocation = () => {
    if (!navigator.geolocation) {
      Swal.fire(
        "Opps!",
        "Geolocation is not supported by your browser",
        "error"
      );
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        () => {
          Swal.fire(
            "Opps!",
            "Unable to retrieve your location, please enable location access in your browser",
            "error"
          );
        }
      );
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <React.Fragment>
      <div class="login-box">
        <div class="card card-outline card-primary">
          <div class="card-header text-center">
            <div class="h1">
              <img src="/assets/img/logo.png" alt="logo" width="150px" />
            </div>
          </div>
          <div class="card-body">
            <h5 className="text-center">Welcome Back!</h5>
            <p class="login-box-msg">Create your account now.</p>
            <form autoComplete="off">
              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
                <input
                  type="text"
                  ref={nameRef}
                  class="form-control"
                  placeholder="Name"
                />
              </div>

              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
                <input
                  type="text"
                  ref={shopNameRef}
                  class="form-control"
                  placeholder="Shop /Business / Store Name"
                />
              </div>

              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setShopType(e.target.value);
                  }}
                >
                  <option>---Select Business Type---</option>
                  <option value="Customer Service Point">
                    Customer Service Point
                  </option>
                  <option value="Mobile and Telecom">Mobile and Telecom</option>
                  <option value="Internet Cafe">Internet Cafe</option>
                  <option value="Tours and Travels">Tours and Travels</option>
                  <option value="General Store">General Store</option>
                  <option value="Electrical and Electronics">
                    Electrical and Electronics
                  </option>
                  <option value="Xerox and Stationery">
                    Xerox and Stationery
                  </option>
                  <option value="Medical and Pharmacy">
                    Medical and Pharmacy
                  </option>
                  <option value="Clothes and Garments">
                    Clothes and Garments
                  </option>
                  <option value="Hotel and Restaurant">
                    Hotel and Restaurant
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-mobile"></span>
                  </div>
                </div>
                <input
                  type="text"
                  ref={mobileRef}
                  class="form-control"
                  placeholder="Mobile Number"
                />
              </div>

              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
                <input
                  type="text"
                  ref={emailRef}
                  class="form-control"
                  placeholder="Email ID"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
                <input
                  type="password"
                  ref={passwordRef}
                  class="form-control"
                  placeholder="Password"
                />
              </div>

              <div class="row">
                <button
                  type="button"
                  onClick={signupHandler}
                  class="btn btn-primary btn-block"
                >
                  Sign Up
                </button>
              </div>

              <div class="row mt-3">
                <div class="col-12 mx-auto">
                  <p
                    class="text-center"
                    style={{ color: "grey", fontSize: "14px" }}
                  >
                    Do you already have an account?
                  </p>
                </div>
              </div>

              <div class="row">
                <button
                  type="button"
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                  class="btn btn-default btn-block"
                >
                  Login
                </button>
              </div>

              <div class="row mt-3">
                <div class="col-12 mx-auto">
                  <p
                    class="text-center"
                    style={{ color: "grey", fontSize: "14px" }}
                  >
                    By clicking login or sign up, you are confirming that you
                    have read our{" "}
                    <a
                      href="#"
                      //  target="_blank"
                    >
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="#"
                      // target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <OtpDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        errMsg={errMsg}
        token={token}
        passwordRef={passwordRef}
      />
    </React.Fragment>
  );
};

export default Signup;
