import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import EmployeeService from "../../../services/Admin/EmployeeService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { useHistory } from "react-router-dom";

const EditEmployee = (props) => {
  const history = useHistory();
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  const [employeeType, setEmployeeType] = useState();
  const [status, setStatus] = useState();

  const formRef = useRef();
  const empIdRef = useRef();
  const nameRef = useRef();
  const mobileRef = useRef();
  const emailRef = useRef();

  const editEmployeeHandler = async () => {
    const empId = empIdRef.current.value;

    const name = nameRef.current.value;
    const mobile = mobileRef.current.value;
    const email = emailRef.current.value;

    if (!empId) {
      Swal.fire("Opps!", "Invalid EMP ID", "error");
      return;
    }

    if (!employeeType) {
      Swal.fire("Opps!", "Invalid Employee Type", "error");
      return;
    }

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    if (!mobile) {
      Swal.fire("Opps!", "Invalid Mobile", "error");
      return;
    }

    if (!email) {
      Swal.fire("Opps!", "Invalid Email", "error");
      return;
    }

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const id = props.id ? props.id : props.match.params.id;

      const responseData = await EmployeeService.update(
        token,
        id,
        empId,
        employeeType,
        name,
        mobile,
        email,
        status,
        menuItems
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.id ? props.id : props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await EmployeeService.fetchById(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
        setEmployeeType(responseData.data.employee_type);
        setStatus(responseData.data.status);

        const _nav_menus = responseData.data.nav_menus;
        if (_nav_menus && _nav_menus !== null) {
          setMenuItems(_nav_menus);
        } else {
          setMenuItems(sampleMenu);
        }
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const sampleMenu = [
    {
      title: "Dashboard",
      subMenu: null,
      isEnabled: true,
    },
    {
      title: "Manage Users",
      isEnabled: true,
      subMenu: [
        {
          title: "Add User",
          isEnabled: true,
        },
        {
          title: "User List",
          isEnabled: true,
        },
      ],
    },
    {
      title: "Commercial Master",
      isEnabled: true,
      subMenu: [
        {
          title: "Manage Package",
          isEnabled: true,
        },
        {
          title: "Set Commercial",
          isEnabled: true,
        },
      ],
    },
    {
      title: "Recharge",
      isEnabled: true,
      subMenu: [
        {
          title: "Recharge Report",
          isEnabled: true,
        },
        {
          title: "Live Recharge Report",
          external: true,
          isEnabled: true,
        },
        {
          title: "Dispute Recharge Report",
          isEnabled: true,
        },
        {
          title: "Manage Recharge API",
          isEnabled: true,
        },
        {
          title: "Switch Recharge API",
          isEnabled: true,
        },
        {
          title: "Set Operator Code",
          isEnabled: true,
        },
      ],
    },
    {
      title: "Prepaid Card",
      isEnabled: true,
      subMenu: [
        {
          title: "User List",
          isEnabled: true,
        },
        {
          title: "Card List",
          isEnabled: true,
        },
        {
          title: "Transactions",
          isEnabled: true,
        },
      ],
    },
    {
      title: "Lead Management",
      isEnabled: true,
      subMenu: [
        {
          title: "Add Lead Product",
          isEnabled: true,
        },
        {
          title: "Lead Product List",
          isEnabled: true,
        },
      ],
    },
    {
      title: "Reports",
      isEnabled: true,
      subMenu: [
        {
          title: "NSDL PAN Card",
          isEnabled: true,
        },
        {
          title: "UTI PAN Card",
          isEnabled: true,
        },
        {
          title: "Insurance",
          isEnabled: true,
        },
        {
          title: "PG",
          isEnabled: true,
        },
        {
          title: "CP Summary",
          isEnabled: true,
        },
      ],
    },
    {
      title: "Wallet",
      isEnabled: true,
      subMenu: [
        {
          title: "Wallet Topup",
          isEnabled: true,
        },
        {
          title: "Wallet Topup History",
          isEnabled: true,
        },
        {
          title: "Transactions",
          isEnabled: true,
        },
        {
          title: "Fund Request",
          isEnabled: true,
          subMenu: [
            {
              title: "Pending Fund Request",
              isEnabled: true,
            },
            {
              title: "Fund Request History",
              isEnabled: true,
            },
          ],
        },
      ],
    },
    {
      title: "Setup",
      isEnabled: true,
      subMenu: [
        {
          title: "Manage Employee",
          isEnabled: true,
        },
        {
          title: "Settings",
          isEnabled: true,
        },
      ],
    },
    {
      title: "Logout",
      subMenu: null,
      isEnabled: true,
    },
  ];

  const [menuItems, setMenuItems] = useState();

  const updateList = (item, isEnabled) => {
    const updatedList = [...menuItems];
    updatedList[item].isEnabled = isEnabled;
    setMenuItems(updatedList);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Edit Employee">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Edit Employee</h3>

                    <div class="card-tools">
                      <button
                        type="submit"
                        class="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/admin/employee_list");
                        }}
                      >
                        Employee List
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      {data && (
                        <form ref={formRef} autoComplete="off">
                          <div class="card-body">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">
                                EMP ID
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Employee ID"
                                  ref={empIdRef}
                                  defaultValue={data.emp_id}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">
                                Employee Type
                              </label>
                              <div class="col-sm-9">
                                <select
                                  name="title"
                                  class="form-control"
                                  onChange={(e) => {
                                    setEmployeeType(e.target.value);
                                  }}
                                >
                                  <option value={data.employee_type}>
                                    {data.employee_type}
                                  </option>
                                  <option value="Admin">Admin</option>
                                  <option value="Staff">Staff</option>
                                </select>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">
                                Name
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Name"
                                  ref={nameRef}
                                  defaultValue={data.name}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">
                                Email ID
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Email"
                                  ref={emailRef}
                                  defaultValue={data.email}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">
                                Mobile Number
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Mobile"
                                  ref={mobileRef}
                                  defaultValue={data.mobile}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">
                                Status
                              </label>
                              <div class="col-sm-9">
                                <select
                                  name="title"
                                  class="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value={data.status}>
                                    {data.status}
                                  </option>
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>

                            <div class="text-center">
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={editEmployeeHandler}
                              >
                                Submit
                              </button>
                              <button type="reset" class="btn btn-default ml-5">
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                    <div class="col-md-6 mx-auto">
                      {data && menuItems && (
                        <form ref={formRef} autoComplete="off">
                          <div class="card-body">
                            {menuItems &&
                              menuItems.map((e, index) => {
                                return (
                                  <div class="form-group form-check">
                                    <input
                                      type="checkbox"
                                      checked={e.isEnabled}
                                      onChange={() =>
                                        updateList(index, !e.isEnabled)
                                      }
                                    />
                                    <label
                                      className={`form-check-label pl-2 ${
                                        e.subMenu ? "pb-3" : ""
                                      }`}
                                      for="exampleCheck1"
                                    >
                                      {e.title}
                                    </label>

                                    {e.subMenu &&
                                      e.subMenu.map((ee, index2) => {
                                        return (
                                          <div class="form-group form-check">
                                            <input
                                              type="checkbox"
                                              checked={ee.isEnabled}
                                              onChange={() => {
                                                // updateList(index, !e.isEnabled)

                                                const updatedList = [
                                                  ...menuItems,
                                                ];
                                                updatedList[index].subMenu[
                                                  index2
                                                ].isEnabled = !ee.isEnabled;
                                                setMenuItems(updatedList);
                                              }}
                                            />
                                            <label
                                              class="form-check-label pl-2"
                                              for="exampleCheck1"
                                            >
                                              {ee.title}
                                            </label>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default EditEmployee;
