import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./style.css";

const UtilityServicesBox = () => {
  const history = useHistory();

  const [services, setServices] = useState([
    {
      name: "Fastag",
      image: "/images/fastag-icon.png",
      href: "/user/bill_payment/Fastag",
    },
    {
      name: "Electricity",
      image: "/images/electricity-icon.png",
      href: "/user/bill_payment/Electricity",
    },
    {
      name: "Postpaid",
      image: "/images/prepaid-icon.png",
      href: "/user/bill_payment/Postpaid",
    },
    {
      name: "EMI",
      image: "/images/emi-icon.png",
      href: "/user/bill_payment/EMI",
    },
    {
      name: "Gas Cylinder",
      image: "/images/gas-icon.png",
      href: "/user/bill_payment/LPG Booking",
    },
    {
      name: "Landline",
      image: "/images/landline-icon.png",
      href: "/user/bill_payment/Landline",
    },
    {
      name: "Cable TV",
      image: "/images/cable-tv-icon.png",
      href: "/user/bill_payment/Cable TV",
    },
    {
      name: "Piped Gas",
      image: "/images/gas-icon.png",
      href: "/user/bill_payment/Gas",
    },
    {
      name: "Broadband",
      image: "/images/broadband-icon.png",
      href: "/user/bill_payment/Broadband",
    },
    {
      name: "Insurance",
      image: "/images/insurance-icon.png",
      href: "/user/bill_payment/Insurance",
    },
    {
      name: "Water",
      image: "/images/water-icon.png",
      href: "/user/bill_payment/Water",
    },
  ]);

  const navigate = (path) => {
    history.push(path);
  };

  return (
    <React.Fragment>
      <div className="dash-serviceBoxTitle">Utility Payments</div>
      <div className="row pt-2">
        {services.map((value, key) => (
          <div className="col-sm-2">
            <div
              className="card dash-serviceBoxBg"
              style={{
                backgroundImage: `url("/images/utility-card-bg.png")`,
                backgroundPosition: "center",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                // backgroundPosition: "left top",
              }}
            >
              {/* <img
                src="/images/15.png"
                alt="Card image"
                style={{ width: "100%" }}
              /> */}
              {/* <div className="card-img-overlay"> */}
              <div
                className="dash-serviceBox"
                // style={{ background: `url("/images/15.png"})` }}
                onClick={() => {
                  navigate(value.href);
                }}
              >
                <div className="service-image">
                  <a href>
                    {/* className="img-thumbnail rounded-circle avatar-md"  */}
                    <img className="avatar-md" alt="logo" src={value.image} />
                  </a>
                </div>
                <div className="service-content">
                  <h6>{value.name}</h6>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default UtilityServicesBox;
