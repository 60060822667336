import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";

const Templete = (props) => {
  return (
    <div class="wrapper">
      <Header />
      <Menu />

      <div className="content-wrapper">
        <div className={!props.isHide ? "content-header" : ""}>
          {!props.isHide && (
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">{props.t2}</h1>
                </div>
                {/* /.col */}

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">{props.t1}</a>
                    </li>
                    <li className="breadcrumb-item active">{props.t2}</li>
                  </ol>
                </div>
              </div>
            </div>
          )}
        </div>

        {props.children}
      </div>

      <Footer />
    </div>
  );
};

export default Templete;
