import React, { useRef, useContext } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import AuthService from "../../../services/User/AuthService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import { useHistory } from "react-router-dom";

const OtpDialog = (props) => {
  const history = useHistory();

  const loadingCtx = useContext(LoadingContext);

  const otpRef = useRef();
  const emailOtpRef = useRef();

  const otpVerifyHandler = async (e) => {
    e.preventDefault();

    const otp = otpRef.current.value;
    const emailOtp = emailOtpRef.current.value;

    if (!otp || isNaN(otp)) {
      Swal.fire("Opps!", "Invalid OTP", "error");
      return;
    }

    if (!emailOtp || isNaN(emailOtp)) {
      Swal.fire("Opps!", "Invalid OTP", "error");
      return;
    }

    props.handleClose();

    loadingCtx.startLoading();

    try {
      const data = await AuthService.signupVerifyOtp(
        props.token,
        otp,
        emailOtp,
        props.passwordRef.current.value
      );

      if (!data) {
        props.handleOpen();
        throw new Error("Request Failed!");
      }

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        const accessToken = data.data.access_token;
        const name = data.data.name;
        const user_id = data.data.user_id;
        const user_type = data.data.user_type;
        const mobile = data.data.mobile;
        const email = data.data.email;
        const fpay_outlet_onboarded = data.data.fpay_outlet_onboarded;

        localStorage.setItem("timestamp", new Date().getTime());
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem(
          "user",
          JSON.stringify({
            name: name,
            user_type: user_type,
            user_id: user_id,
            mobile: mobile,
            email: email,
          })
        );

        if (fpay_outlet_onboarded == "YES") {
          localStorage.setItem("is_outlet", true);
        } else {
          localStorage.setItem("is_outlet", false);
        }

        props.handleClose();

        // history.push("/user/dashboard");
        window.location.href = "user/dashboard";
        //   window.location.reload();
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      props.handleOpen();
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose}>
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Verify OTP</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-success" role="alert">
              <b>Success! </b> {props.errMsg}
            </div>

            <div class="input-group mb-3">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
              <input
                type="text"
                ref={otpRef}
                class="form-control"
                placeholder="Mobile OTP"
                maxLength={6}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>

            <div class="input-group mb-3">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
              <input
                type="text"
                ref={emailOtpRef}
                class="form-control"
                placeholder="Email OTP"
                maxLength={6}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={otpVerifyHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default OtpDialog;
