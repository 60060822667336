import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./style.css";

const RechargeServicesBox = () => {
  const history = useHistory();

  const [services, setServices] = useState([
    {
      name: "Mobile Recharge",
      image: "/images/prepaid-icon.png",
      bgImage: "/images/mobile-recharge-card-bg.png",
      href: "/user/recharge/prepaid",
    },
    {
      name: "DTH Recharge",
      image: "/images/dth-recharge-icon.png",
      bgImage: "/images/dth-recharge-card-bg.png",
      href: "/user/recharge/dth",
    },
    {
      name: "Google Play Recharge",
      image: "/images/google-play-icon-2.png",
      bgImage: "/images/google-play-card-bg.png",
      href: "/user/recharge/google_play",
    },
  ]);

  const navigate = (path) => {
    history.push(path);
  };

  return (
    <React.Fragment>
      <div className="dash-serviceBoxTitle">Recharge</div>
      <div className="row pt-2">
        {services.map((value, key) => (
          <div className="col-sm-2">
            <div
              className="card dash-serviceBoxBg"
              style={{
                backgroundImage: `url(${value.bgImage})`,
                backgroundPosition: "center",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                // backgroundPosition: "left top",
              }}
            >
              {/* <img
                src="/images/15.png"
                alt="Card image"
                style={{ width: "100%" }}
              /> */}
              {/* <div className="card-img-overlay"> */}
              <div
                className="dash-serviceBox"
                // style={{ background: `url("/images/15.png"})` }}
                onClick={() => {
                  navigate(value.href);
                }}
              >
                <div className="service-image">
                  <a href>
                    {/* className="img-thumbnail rounded-circle avatar-md"  */}
                    <img className="avatar-md" alt="logo" src={value.image} />
                  </a>
                </div>
                <div className="service-content">
                  <h6>{value.name}</h6>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default RechargeServicesBox;
