import common from "../../utill/Common";

class EmployeeService {
  add = async (
    token,
    emp_id,
    password,
    employee_type,
    name,
    mobile,
    email,
    status
  ) => {
    const url = common.baseUrl + "admin/employee/add";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        emp_id,
        password,
        employee_type,
        name,
        mobile,
        email,
        status,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  update = async (
    token,
    id,
    emp_id,
    employee_type,
    name,
    mobile,
    email,
    status,
    nav_menus
  ) => {
    const url = common.baseUrl + "admin/employee/update";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        emp_id,
        employee_type,
        name,
        mobile,
        email,
        status,
        nav_menus,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchById = async (token, id) => {
    const url = common.baseUrl + "admin/employee/fetch_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchAll = async (token) => {
    const url = common.baseUrl + "admin/employee/list";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  delete = async (token, id) => {
    const url = common.baseUrl + "admin/employee/delete";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new EmployeeService();
